import { ReactNode, createContext, useContext, useState } from 'react';

interface RawModeContextType {
  isRaw: boolean;
  setIsRaw: (value: boolean) => void;
}

const RawModeContext = createContext<RawModeContextType | undefined>(undefined);

export const RawModeProvider = ({
  children,
  initialMode,
}: {
  children: ReactNode;
  initialMode: boolean;
}) => {
  const [isRaw, setIsRaw] = useState(initialMode || false);

  return (
    <RawModeContext.Provider value={{ isRaw, setIsRaw }}>
      {children}
    </RawModeContext.Provider>
  );
};

export const useRawMode = () => {
  const context = useContext(RawModeContext);
  if (!context) {
    throw new Error('useRawMode must be used within a RawModeProvider');
  }
  return context;
};
